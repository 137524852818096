import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { L } from 'harmony-language';
import { useSnackbar } from '../../../components/notifications/use-snackbar';
import { Load, User } from '../../../types';
import { useUser } from '../../queries/use-user';
import { useDraftsKey, useLoadsKey } from '../../config-hooks';

interface AssignDriverOptions {
    loads: Load[];
    driver: User;
    currentUserIsCarrier: boolean;
}

const assignDriver = async (options: AssignDriverOptions) => {
    const updatedLoads = options.loads.map(load => {
        return {
            id: load.id,
            transportedByUserId: options.driver.id,
            tractorId: options.driver?.driverInfo?.defaultTractorId || null,
            trailerId: options.driver?.driverInfo?.defaultTrailerId || null,
        };
    });

    return (await axios.post(`${API_BASE}/api/drivers/assign-orders`, updatedLoads)).data;
}

export const useAssignDriverMutation = (isDrafts: boolean) => {
    const snackbar = useSnackbar();
    const { user } = useUser();
    const queryKey = isDrafts ? useDraftsKey() : useLoadsKey();
    const queryClient = useQueryClient();

    const mutation =  useMutation({
        mutationFn: assignDriver,
        onMutate: async (options: { loads: Load[], driver: User }) => {
            const { loads, driver } = options;

            await queryClient.cancelQueries(queryKey);

            const previousCache = queryClient.getQueryData<Load[]>(queryKey);

            // Optimistically update to the new value
            if (previousCache) {
                const newLoads = previousCache.map((load) => {
                    if (loads.find(l => l.id === load.id)) {
                        return {
                            ...load,
                            acceptedByUserId: user.id,
                            transportedByUserId: driver.id,
                            tractorId: options.driver?.driverInfo?.defaultTractorId || null,
                            trailerId: options.driver?.driverInfo?.defaultTrailerId || null,
                        };
                    }
                    return load;
                });

                queryClient.setQueryData(queryKey, newLoads);
            }

            return previousCache;
        },
        onError: (_1, _2, context) => {
            if (context) {
                queryClient.setQueryData<Load[]>(queryKey, context);
            }
        },
        onSuccess: (data, variables) => {
            const { driver } = variables;
            snackbar(L.assignedSnackbarSuccess(driver.name));
        },
        // onSettled: () => {
        //     queryClient.invalidateQueries(queryKey);
        // }
    });

    return mutation;
};
